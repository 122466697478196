import React from "react";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./header.css";

const Header = ({ setSelectedComponent }) => {
  return (
    <div className="relative-pos">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <span
              onClick={() => {
                setSelectedComponent("home");
              }}
              className="header-title"
            >
              Luis Wood
            </span>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <span
              onClick={() => {
                setSelectedComponent("resume_home");
              }}
              className="nav-item"
            >
              Resume
            </span>
          </Grid>
        </Grid>
      </Box>

      <Divider className="divider" />
    </div>
  );
};

export default Header;
