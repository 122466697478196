import { React, useState, useEffect } from "react";
// import "../../../styles/motions.css";
import "../../../styles/menus.css";
import "../../../styles/text.css";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import photo from "../../../assets/self.jpeg";
import "./about-me.css";
const AboutMe = ({ setSelectedComponent }) => {
  const [componentVisibility, setComponentVisibility] = useState("hidden");
  useEffect(() => {
    toggleVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleVisibility = () => {
    setComponentVisibility(
      componentVisibility === "visible" ? "hidden" : "visible"
    );
  };

  return (
    <div id="base-element" className={`${componentVisibility}`}>
      <ArrowBackIcon
        className="arrow-icon"
        onClick={() => {
          setSelectedComponent("resume_home");
        }}
        sx={{ fontSize: 40 }}
        color="default"
      />
      <div className="text-body">
        <div className="title-container">
          <span className="title-aboutme">Hello there! </span>
        </div>

        <div className="grid grid-center">
          <img className="self-photo" src={photo} alt="me" />
        </div>
        <p>
          I’m Luis Wood, I’ve been working as a software engineer for 4 years
          now, across different teams.{" "}
        </p>

        <p>
          Since an early age, I’ve always been involved and fascinated with
          technology. In the early days, I learned by myself basic web site
          development with HTML, CSS and Javascript. Although by academic
          background is quite distant from the IT ‘Realm’, in <span className="date-tag">2017</span> I decided
          to make a career shift. I already knew the basics of programming, I
          enrolled in a Java course. In this course we went through Java 2nd
          edition and <span className="keyword-tech">JavaEE</span>, as well as a
          recap on <span className="keyword-tech">web development</span> basics.
        </p>
        <p>
          In <span className="date-tag">November 2017</span> I took my first job as part of an{" "}
          <span className="keyword-tech">Artificial Intelligence </span>team in
          <span className="keyword-company"> Critical Software</span>. In this
          position, I worked on a{" "}
          <span className="keyword-tech">
            Natural Language Processing (NLP)
          </span>{" "}
          project, building a MVP. My first task was to covert a{" "}
          <span className="keyword-tech">NodeJS</span> that was written in one
          week to a <span className="keyword-tech">JavaEE</span>. This one was a
          bit challenging because I had never worked with{" "}
          <span className="keyword-tech"> NodeJS </span>
          before but was definitely an enriching experience. I had the
          opportunity to build, alongside a colleague, the backend of the web
          application, creating the API that would shortly after serve the
          frontend, built on <span className="keyword-tech">HTML+CSS+JS</span>.
          In <span className="date-tag">September 2018</span>, I moved to my current company, within the Critical
          Group,{" "}
          <span className="keyword-company">Critical Techworks (CTW)</span>.
        </p>
        <p>
          On my first project in <span className="keyword-company">CTW</span>, I
          had the opportunity to work a budget management tool. The first couple
          of months were dedicated to move the original application, developed
          in <span className="keyword-tech">Spring Boot</span>, to{" "}
          <span className="keyword-tech">JavaEE</span>. Once again, I had the
          opportunity to learn quite a bit given the chance to work with this
          framework, that was new to me. Alongside the backend in{" "}
          <span className="keyword-tech">JavaEE</span>, our fronted was
          initially developed in{" "}
          <span className="keyword-tech">HTML+CSS+JS</span> and in a few months
          we decided to move into{" "}
          <span className="keyword-tech">AngularJS</span>. This was the most
          challenging portion of the project, since this technology was new to
          everyone on the team, having a collective background in backend. Even
          facing challenges, I feel that we were able to pull it through, being
          able to deliver a product that match the stakeholders expectations,
          soon to be used frequently by different teams.{" "}
        </p>
        <p>
          In <span className="date-tag">December 2019</span>, I was challenged to assume the position of Scrum
          Master, alongside the developer role, in a{" "}
          <span className="keyword-tech">
            Privileged Access Management (PAM)
          </span>{" "}
          team. In the beginning of this new cycle, in this
          <span className="keyword-tech"> cybersecurity </span> team, I
          performed the role of Team leader, helping more junior profiles to
          develop their skills, ensuring the team’s development, not only in
          numbers but also in maturity and establishing connection to
          stakeholders and other teams across the company. The main activities
          of this team were the development of new extensions, connection
          components and plugins, to our main PAM tool,{" "}
          <span className="keyword-tech">Cyberark</span>, and little by little,
          we assumed new responsibilities across the ‘ecosystem’, the operation
          portion of the product, for example. Throughout the different tasks, I
          was able to work with <span className="keyword-tech">Python</span>,
          <span className="keyword-tech"> AutoIT</span>,{" "}
          <span className="keyword-tech">javascript</span> and{" "}
          <span className="keyword-tech"> powershell</span>, in both{" "}
          <span className="keyword-tech">windows</span> and{" "}
          <span className="keyword-tech">linux</span> operative systems,
          configuring and integrating{" "}
          <span className="keyword-tech">Jenkins</span>, running{" "}
          <span className="keyword-tech">ansible</span> playbooks to perform
          specific tasks regarding server management, as well getting to know
          the inner works of <span className="keyword-tech">ITSM</span> and the
          incident management process.
        </p>
        <p>From <span className="date-tag">August 2022</span> until <span className="date-tag">February 2023</span> I was involved in 2 main topics:
          <span className="keyword-tech"> Detection as Code</span> and <span className="keyword-tech">Cyber Threat Inteligence (CTI) </span>
          With the former, I had the chance to work with <span className="keyword-tech">Github Actions </span>
          and <span className="keyword-tech">Ansible</span> to orchestrate the handling and validation of <span className="keyword-tech">Sigma Rules </span>
          to be afterwards be deployed onto <span className="keyword-tech"> Splunk</span>. With <span className="keyword-tech">CTI</span> I was responsible for
          implementing POCs of multiple tools, as well as custom development with <span className="keyword-tech">Python</span>. The main objective was to
          centralize sensible reports from different data sources, in order to facilitate the <span className="keyword-tech">vulnerability management </span> process.</p>
          <p>On <span className="date-tag">June 2023</span> I embraced a new challenge in <span className="keyword-company">CTW</span>, working closely with CTW's CISO, the 
          focus was the internal cycbersecurity strategy. I was involved in different topics such as <span className="keyword-tech">Business continuity</span>, designing some 
          continuity scenarios and shaping simulation exercises, <span className="keyword-tech"> Threat Intelligence</span>, by implementing an external tool to grant visibility 
          over digital footprint and potential leaked credentials, <span className="keyword-tech">27001 auditing</span>, coordinating the audits preparation and improvement plans, 
          and general <span className="keyword-tech">Security Awareness</span> by coordinating security campaigns, addressing best practices as well as phishing simulations.
          </p>
      </div>
      <div className="aboutme-footnote">
        <p>Feel free to reach me at <span><a className="link" href="https://www.linkedin.com/in/lpawood/">Linkedin</a></span></p>
      </div>
    </div>
  );
};

export default AboutMe;
