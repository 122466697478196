import { React, useState, useEffect } from "react";
import "../../../styles/motions.css";
import "../../../styles/menus.css";
import "./Skills.css";
import { Chip } from "@mui/material";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ComputerIcon from "@mui/icons-material/Computer";
import TranslateIcon from "@mui/icons-material/Translate";

const Skills = ({ setSelectedComponent }) => {
  const [componentVisibility, setComponentVisibility] = useState("hidden");
  useEffect(() => {
    toggleVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleVisibility = () => {
    setComponentVisibility(
      componentVisibility === "visible" ? "hidden" : "visible"
    );
  };

  return (
    <div id="base-element" className={`${componentVisibility}`}>
      <ArrowBackIcon
        className="arrow-icon"
        onClick={() => {
          setSelectedComponent("resume_home");
        }}
        sx={{ fontSize: 40 }}
        color="default"
      />
      <div className="general-container">
        <div className="group-block">
          <PeopleOutlineIcon className="icon-social" />
          <div className="tags-container">
            <Chip
              className="skill-tag social"
              variant="outlined"
              label="Leadership"
            />
            <Chip
              className="skill-tag social"
              variant="outlined"
              label="Scrum"
            />
            <Chip
              className="skill-tag social"
              variant="outlined"
              label="Agile"
            />
            <Chip
              className="skill-tag social"
              variant="outlined"
              label="Team player"
            />
          </div>
        </div>
        <div className="group-block">
          <ComputerIcon className="icon-tech" />{" "}
          <div className="tags-container">
            <Chip
              className="skill-tag tech"
              variant="outlined"
              label="Detection as Code"
            />
            <Chip
              className="skill-tag tech"
              variant="outlined"
              label="Access Management"
            />
            <Chip
              className="skill-tag tech"
              variant="outlined"
              label="Cyber Threat Intelligence"
            />
            <Chip
              className="skill-tag tech"
              variant="outlined"
              label="Server Management"
            />
            <Chip
              className="skill-tag tech"
              variant="outlined"
              label="Incident Management"
            />
            <Chip
              className="skill-tag tech"
              variant="outlined"
              label="Business Continuity" />
            <Chip className="skill-tag tech"
              variant="outlined"
              label="Vulnerability Management" />
            <Chip
              className="skill-tag tech"
              variant="outlined"
              label="ISO 27001" />
            <Chip
              className="skill-tag tech"
              variant="outlined"
              label="Security Awareness" />
            <Chip
              className="skill-tag tech"
              variant="outlined"
              label="Linux"
            />
            <Chip
              className="skill-tag tech"
              variant="outlined"
              label="Windows"
            />
            <Chip
              className="skill-tag tech"
              variant="outlined"
              label="Python"
            />
            <Chip
              className="skill-tag tech"
              variant="outlined"
              label="Powershell"
            />
            <Chip className="skill-tag tech" variant="outlined" label="Bash" />

            {/* <Chip className="skill-tag tech" variant="outlined" label="Git" /> */}
          </div>
        </div>
        <div className="group-block">
          <TranslateIcon className="icon-language" />
          <div className="tags-container">
            <Chip
              className="skill-tag language"
              variant="outlined"
              label="Portuguese"
            />
            <Chip
              className="skill-tag language"
              variant="outlined"
              label="English"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
