import {React, useState, useEffect} from "react";
import './ProfessionalExperience.css';
import '../../../styles/motions.css';
import '../../../styles/menus.css';

const ProfessionalExperienceDescription = ({description}) => {
    const [visibility, setVisibility] = useState(false)

    useEffect(() => {
    }, [visibility])

    const toggleVisibility = () => {
        setVisibility(!visibility)
    }

  return (
    <div className="professional-experience-description">
      <div className={`show-more-container ${visibility ? 'show-more-open' : 'show-more-closed'}`}>
        <span className="expand-button" onClick={() => toggleVisibility()}>{description === "" ? '' : visibility ? 'Show less' : 'Show more'}</span>
        <p className={`description ${visibility ? 'visible' : 'hidden'}`}>{description}</p>
      </div>
    </div>
  );
};

export default ProfessionalExperienceDescription;