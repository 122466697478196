import React from "react";
import "../../../styles/motions.css";
import "../../../styles/menus.css";
import "./ProfessionalExperience.css";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import ExperienceCard from "./ExperienceCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ProfessionalExperience = ({ setSelectedComponent }) => {
  return (
    <div>
      <ArrowBackIcon
        className="arrow-icon"
        onClick={() => {
          setSelectedComponent("resume_home");
        }}
        sx={{ fontSize: 40 }}
        color="default"
      />
      <VerticalTimeline lineColor="#6a6a6a">
      <ExperienceCard
          expType="work"
          expTitle="Security Operations Engineer"
          expSubtitle="Internal Security Topics - Critical TechWorks"
          expDate="Jun. 2023 - Jul. 2024"
          expTags={[
            { context: "social", label: "Scrum" },
            { context: "tech", label: "Cybersecurity" },
            { context: "tech", label: "Threat Intelligence" },
            { context: "tech", label: "Vulnerability Management" },
            { context: "tech", label: "ISO27001" },
            { context: "tech", label: "Security Awareness" },
            { context: "tech", label: "Business Continuity" },
          ]}
          expDescription="This role implied the tackling of internal 
          cybersecurity topics, from coordinating different teams towards
           vulnerability management, implementation of and external Threat
            Intel platform to the Security Awareness strategy on the 
            corporate level."
        />
      <ExperienceCard
          expType="work"
          expTitle="Cybersecurity Engineer & Scrum Master"
          expSubtitle="Detection as Code & CTI - Critical Techworks"
          expDate="Aug. 2022 - Feb. 2023"
          expTags={[
            { context: "social", label: "Leadership" },
            { context: "social", label: "Stakeholder management" },
            { context: "social", label: "Scrum" },
            { context: "tech", label: "Cybersecurity" },
            { context: "tech", label: "Detection as Code" },
            { context: "tech", label: "Python" },
            { context: "tech", label: "Windows" },
            { context: "tech", label: "Linux" },
            { context: "tech", label: "Ansible" },
            { context: "tech", label: "Github Actions" },
          ]}
          expDescription="In this project, there were two main focus: Detection as Code, where I had the chance to be involved
          in the implementation of automation processes in the handling of Sigma rules to be translated into Splunk queries;
          and Cyber Threat Intelligence, where I had the opportunity to integrate POCs of multiple tools to centralize sensible information, 
          in order to optimize the vulnerability management process.
          Alongside the technical input, I was responsible as well, as Scrum Master as a parallel role, to support the team
          and Product owner, for some stakeholder management and agile coaching"
        />
        <ExperienceCard
          expType="work"
          expTitle="Cybersecurity Engineer & Scrum Master"
          expSubtitle="Privileged Access Management - Critical TechWorks"
          expDate="Jan. 2020 - Aug. 2022"
          expTags={[
            { context: "social", label: "Leadership" },
            { context: "social", label: "Stakeholder Management" },
            { context: "social", label: "Scrum" },
            { context: "tech", label: "Server Management" },
            { context: "tech", label: "Incident Management" },
            { context: "tech", label: "PAM" },
            { context: "tech", label: "Javacript" },
            { context: "tech", label: "Windows" },
            { context: "tech", label: "Linux" },
            { context: "tech", label: "AutoIT" },
          ]}
          expDescription="As Scrum Master and Cybersecurity Engineer within this Privileged Access Management
           team, I coordinated the implementation of the Scrum Framework, I had the opportunity to work
            closely with the development of plugins to integrate different technologies into a PAM 
            Solution. I also took part in the operations of the product, which involved incident management and
            server patching and updating. I managed to ramp-up the team, created entirely, apart from myself, by colleagues
             new to the company, that lead me to play a supportive role to team members as a mentor in 
             specific technologies and also an active role giving input regarding technical decisions."
        />

        <ExperienceCard
          expType="Training"
          expTitle="Cyberark admnistration Training"
          expSubtitle="Cyberark"
          expDate="Jan. 2020"
          expTags={[
            { context: "tech", label: "Cyberark" },
            { context: "tech", label: "PAM" },
            { context: "tech", label: "Cybersecurity" },
          ]}
          expDescription="Official Cyberark training about Administration of their Privileged Access Management solution. Common"
        />
        <ExperienceCard
          expType="Training"
          expTitle="Cyberark Installation Training"
          expSubtitle="Cyberark"
          expDate="Jan. 2020"
          expTags={[
            { context: "tech", label: "Cyberark" },
            { context: "tech", label: "PAM" },
            { context: "tech", label: "Cybersecurity" },
          ]}
          expDescription="Official Cyberark training about Installation of their Privileged Access Management solution. Common"
        />
        <ExperienceCard
          expType="Training"
          expTitle="Professional Scrum Master Certification"
          expSubtitle="Scrum.org"
          expDate="Dez. 2019"
          expTags={[
            { context: "social", label: "Agile" },
            { context: "social", label: "Scrum" },
          ]}
          expDescription=""
        />
        <ExperienceCard
          expType="work"
          expTitle="Fullstack Developer"
          expSubtitle="Critical Techworks"
          expDate="Sep. 2018 - Dez. 2019"
          expTags={[
            { context: "social", label: "Scrum" },
            { context: "tech", label: "JavaEE" },
            { context: "tech", label: "Javascript" },
            { context: "tech", label: "AngularJS" },
            { context: "tech", label: "SpringBoot" },
            { context: "tech", label: "PostgreSQL" },
            { context: "tech", label: "Web Development" },
          ]}
          expDescription="As a full stack developer I worked since the beginning of this project 
          working initially with Spring Boot and Javascript vanilla, changing the technologic stack
           afterwards to JavaEE and AngularJS. I had the opportunity to learn a great deal about 
           new technologies and further develop my skills in backend development."
        />
        <ExperienceCard
          expType="work"
          expTitle="Junior Fullstack Developer"
          expSubtitle="Critical Software"
          expDate="Nov. 2017 - Sep. 2018"
          expTags={[
            { context: "social", label: "Scrum" },
            { context: "tech", label: "JavaEE" },
            { context: "tech", label: "Javascript" },
            { context: "tech", label: "NodeJS" },
            { context: "tech", label: "Artificial Inteligence" },
            { context: "tech", label: "Web Development" },
            { context: "tech", label: "Python" },
          ]}
          expDescription="As my entry job in the IT Industry, this team allowed me to learn
          new topics, specially regarding artificial intelligence, NodeJS in
          the first stage of the project while developing a MVP. I quickly
          managed to learn the tools I needed to actively contribute to the
          team and taking part in technological decisions and strategies."
        />
        <ExperienceCard
          expType="Training"
          expTitle="Certified Java Programming Course"
          expSubtitle="Grupo Rumos"
          expDate="Sep. 2016 - Jun. 2017"
          expTags={[
            { context: "tech", label: "Java" },
            { context: "tech", label: "JavaEE" },
            { context: "tech", label: "HTML" },
            { context: "tech", label: "CSS" },
            { context: "tech", label: "Javacript" },
          ]}
          expDescription=""
        />
        <ExperienceCard
          expType="Training"
          expTitle="Master degree in Sound Design"
          expSubtitle="Universidade Católica Portuguesa"
          expDate="Sep. 2013 - Mar. 2015"
          expTags={[]}
          expDescription=""
        />
        <ExperienceCard
          expType="Training"
          expTitle="Bachelor's degree in Artistic Studies"
          expSubtitle="Faculdade de Letras da Universidade de Coimbra"
          expDate="Sep. 2010 - Jul. 2013"
          expTags={[]}
          expDescription=""
        />
      </VerticalTimeline>
    </div>
  );
};

export default ProfessionalExperience;
