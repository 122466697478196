import React from "react";
import ReactDOM from "react-dom";

import "./styles/index.css";
import "typeface-roboto";
import ProtoRouter from "./components/proto-router/ProtoRouter";
import { useState } from "react";

const App = () => {
  const [scrollVisible, setScrollVisible] = useState(false);
  const scrollableComponents = ["resume_professional_experience", "resume_about_me"];

  const configureScrollStyle = (component) => {
    if (scrollableComponents.includes(component) || (component === "resume_skills") && window.innerWidth < 768) {
      document.getElementsByTagName("html")[0].setAttribute("style", `overflow: visible;`);
    }
    else {
      document.getElementsByTagName("html")[0].setAttribute("style", `overflow: hidden;`);
    }
  }

  return (
    <div className={`main-container ${scrollVisible}`}>
      <ProtoRouter configureScrollStyle={configureScrollStyle} />
    </div>
  );
};

ReactDOM.render(<App />, document.querySelector("#root"));
