import React, { useState, useEffect } from "react";
// import "../../../styles/motions.css";
import "../../../styles/text.css";
import "../../../styles/transitions.css";

const ResumeHome = ({ setSelectedComponent }) => {
  const [componentVisibility, setComponentVisibility] = useState("hidden");
  useEffect(() => {
    toggleVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleVisibility = () => {
    setComponentVisibility(componentVisibility === "visible" ? "hidden" : "visible");
  };

  return (
    <div className="base-container">
      <div
        id="base-element"
        className={`centered-circle ${componentVisibility}`}
      >
        <div
          onClick={() => {
            setSelectedComponent("resume_about_me");
          }}
          className={`hovicon effect-8`}
        >
          <span className="circle-tag dark-blue">About me</span>
        </div>
        <div
          onClick={() => {
            setSelectedComponent("resume_skills");
          }}
          className={`hovicon effect-8 `}
        >
          <span className="circle-tag dark-blue">Skills</span>
        </div>
        <div
          onClick={() => {
            setSelectedComponent("resume_professional_experience");
          }}
          className={`hovicon effect-8`}
        >
          <span className="circle-tag dark-blue">Professional Experience</span>
        </div>
      </div>
    </div>
  );
};

export default ResumeHome;
