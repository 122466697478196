import React, { useState, useEffect } from "react";
// import Home from "../home/Home";
import Home from "../home/home"
import ResumeHome from "../resume/resume-home/ResumeHome";
import Header from "../header/header";
import Skills from "../resume/skills/skills";
import ProfessionalExperience from "../resume/professional-experience/ProfessionalExperience";
import AboutMe from "../resume/about-me/AboutMe";

const ProtoRouter = ({ configureScrollStyle }) => {
  const [selectedComponent, setSelectedComponent] = useState("home");

  useEffect(() => {
    renderComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComponent]);

  const renderComponent = () => {
    switch (selectedComponent) {
      case "resume_home":
        configureScrollStyle("resume_home");
        return <ResumeHome setSelectedComponent={setSelectedComponent} />;
      case "resume_skills":
        configureScrollStyle("resume_skills");
        return <Skills setSelectedComponent={setSelectedComponent} />;
      case "resume_professional_experience":
        configureScrollStyle("resume_professional_experience");
        return (
          <ProfessionalExperience setSelectedComponent={setSelectedComponent} />
        );
      case "resume_about_me":
        configureScrollStyle("resume_about_me");
        return <AboutMe setSelectedComponent={setSelectedComponent} />;
      case "home":
      default:
        configureScrollStyle("home");
        return <Home setSelectedComponent={setSelectedComponent} />;
    }
  };

  return (
    <div>
      <Header setSelectedComponent={setSelectedComponent} />
      {renderComponent()}
    </div>
  );
};

export default ProtoRouter;
