import { React, useState, useEffect } from "react";
import './home.css'
import "../../styles/transitions.css";
import "../../styles/index.css";

const Home = () => {
    const [componentVisibility, setComponentVisibility] = useState('hidden')
  
    useEffect(() => {
      toggleVisibility()
    }, [])
  
    const toggleVisibility = () => {
      setComponentVisibility('visible')
    }

    return (
      <div>
        <div id='base-element' className={`centered ${componentVisibility}`}>
            <div className="major-label-container"><span className="major-label dark-blue"> Cybersecurity Engineer </span></div>
            <div className="major-label-container"><span className="major-label light-blue"> Technology enthusiast </span></div>
            <div className="major-label-container"><span className="label"> Based in Portugal </span></div>
        </div>
        </div>
    )
}

export default Home