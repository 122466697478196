import React from "react";
import { Chip } from "@mui/material";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import ComputerIcon from "@mui/icons-material/Computer";
import ProfessionalExperienceDescription from "./ProfessionalExperienceDescription";
import SchoolIcon from '@mui/icons-material/School';

const ExperienceCard = ({
  expType,
  expTitle,
  expSubtitle,
  expDate,
  expTags,
  expDescription,
}) => {
  const renderTags = () => {
    const tags = expTags.map((tag) => {
      return (
        <Chip
          className={`pe-skill-tag ${tag.context}`}
          variant="outlined"
          label={tag.label}
        />
      );
    });
    return tags;
  };

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: "#212121", color: "#E8E8E8" }}
      contentArrowStyle={{ borderRight: "7px solid  #212121" }}
      date={expDate}
      iconStyle={expType === "work" ? { background: "#4c6663", color: "#E8E8E8" } : { background: "#0B4F6C", color: "#E8E8E8" }}
      icon={expType === "work" ? <ComputerIcon /> : <SchoolIcon />}
    >
      <h3 className="vertical-timeline-element-title">{expTitle}</h3>
      <h4 className="vertical-timeline-element-subtitle">{expSubtitle}</h4>
      {renderTags()}
      <ProfessionalExperienceDescription description={expDescription}/>
    </VerticalTimelineElement>
  );
};

export default ExperienceCard;
